export const getters = {
  accessToken: state => state.accessToken,
  isLoggedIn: state => state.accessToken,
  userAccountPass: state => state.userAccountPass,
  accountRole: state => state.accountRole,
  vhColumns (state) {
    console.log('state', state.myCarList)
    const arr = []
    if (state.myCarList.myCarList) {
      const list = state.myCarList.myCarList
      list.forEach(x => {
        arr.push(x.plate_number)
      })
    }

    return arr
  }
}
