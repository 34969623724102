
// import * as util from '../api/util'

export const actions = {

  // async DEFAULTPROVINCEACTIONS ({ commit, state }, force) {
  //   if (!state.defaultProvince.defaultProvince || force) {
  //     const para = {
  //       customer_id: state.userInfo.customer_id,
  //       type: 20,
  //       pid: 0
  //     }
  //     try {
  //       const data = await utils.get_default_vh(para)
  //       commit('DEFAULTPROVINCE', data)
  //     } catch (e) {
  //       return defaultH5Config
  //     }
  //   }
  // }
}
