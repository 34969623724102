export const state = {
  accessToken: {
    accessToken: '123'
    // accessToken: ''
  },
  userAccountPass: {
    userAccountPass: null
  },
  accountRole: {
    accountRole: 1 // 1是社会人士普通用户   2是商户
    // accountRole: 'business'
  },
  code: {
    code: 123456
  },
  historyCode: {
    historyCode: 123457
  },
  sid: {
    sid: null
    // sid: 'testD'
  },
  channelPathID: {
    channelPathID: null
  },
  channelPath: {
    channelPath: null
  },
  queryLogHistory: {
    queryLogHistory: null
  },
  wxZfbAuthInfo: {
    wxZfbAuthInfo: null
  },
  phone: {
    phone: null
  },
  plateNumber: {
    plateNumber: null
  },
  adverInfo: {
    adverInfo: null
  },
  payType: {
    payType: null
  },
  saveRouteQueryData: {
    saveRouteQueryData: null
  },
  appid: {
    appid: null
  },
  myCarList: {
    myCarList: null
  },
  openID: {
    openID: null
  },
  applyBillInfo: {
    applyBillInfo: null
  },
  escapeInfo: {
    escapeInfo: null
  }
}
